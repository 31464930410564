import {inject, Injectable} from '@angular/core';
import {ApiClientService} from '../../../../../../src/app/core/api-client.service';
import {CachePriority, HttpAction} from '../../../../../../src/app/core/enums';
import {ApiClientOptions} from '../../../../../../src/app/core/models';
import {Observable} from 'rxjs';
import {ENV_CONFIG} from '../../environment.config';
import {SessionStorageService} from '../../../../../../src/app/localstorage/session-storage.service';
import {GlobalConstants} from '../../../../../../src/app/globals';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiClientService = inject(ApiClientService);
  private environmentConfig = inject(ENV_CONFIG);

  constructor() {
  }

  invokeLSApi<T>(path: string, action: HttpAction, content?: any, options: ApiClientOptions = new ApiClientOptions()): Observable<T> {
    return this.apiClientService.InvokeApi(
      path,
      action,
      content,
      {
        ...options,
        environmentRouteURL: this.environmentConfig.lsApiUrl,
        authKey: SessionStorageService.Get(GlobalConstants.AuthKey),
        shardKey: SessionStorageService.Get(GlobalConstants.ShardKey),
        cacheLevel: CachePriority.NoCache
      }
    );
  }

  invokeMPApi<T>(path: string, action: HttpAction, content?: any, options: ApiClientOptions = new ApiClientOptions()): Observable<T> {
    return this.apiClientService.InvokeApi(
      path,
      action,
      content,
      {
        ...options,
        environmentRouteURL: this.environmentConfig.mpApiUrl,
        authKey: SessionStorageService.Get(GlobalConstants.MPToken),
        shardKey: '',
        cacheLevel: CachePriority.NoCache
      }
    );
  }

  invokeIDSApi<T>(path: string, action: HttpAction, content?: any, options: ApiClientOptions = new ApiClientOptions()): Observable<T> {
    return this.apiClientService.InvokeApi(
      path,
      action,
      content,
      {
        ...options,
        environmentRouteURL: this.environmentConfig.idsApiUrl,
        authKey: SessionStorageService.Get(GlobalConstants.IDSToken),
        shardKey: '',
        cacheLevel: CachePriority.NoCache
      }
    );
  }
}
